import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

// Axios
function getCookie(name) { // No nee for dependency just to get cookie, may bite me in the ass
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let split = el.split('=');
    cookie[split[0].trim()] = split.slice(1).join("=");
  })
  return cookie[name];
}

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_APILOCATION
axios.defaults.withCredentials = true
axios.interceptors.request.use(request => {
  const csrfToken = getCookie('csrftoken')
  request.headers['X-CSRFToken'] = csrfToken
  return request
})
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && error.response.status == 403) {
    store.dispatch('logOut')
      .then(() => {
        router.push({ name: 'AuthLogin', query: { from: router.currentRoute.value.fullPath }})
      })
  }
  return Promise.reject(error)
})

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app')
