import { createStore } from 'vuex'
import axios from 'axios';
import { normalize, schema } from 'normalizr';

import viewer from './viewer'


// Pagination function for axios
function getPaginatedResults(url, params, handler) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params })
      .then((response) => {
        handler(response.data.results);
        let next = response.data.next;
        // Recursive function to handle pagination
        function getNextPage(nextUrl) {
          if (nextUrl) {
            axios.get(nextUrl)
              .then((response) => {
                handler(response.data.results);
                getNextPage(response.data.next); // Call recursively for next page
              })
              .catch((error) => {
                reject(error); // Reject promise if there's an error
              });
          } else {
            resolve(); // Resolve promise when no more pages available
          }
        }
        getNextPage(next);
      })
      .catch((error) => {
        reject(error); // Reject promise if there's an error
      });
  });
}




// Keywords
const keywordSchema = new schema.Entity('keywords');
// Projects
const projectSchema = new schema.Entity('projects', {
  keywords: [keywordSchema]
});
// Documents
const pageSchema = new schema.Entity('pages');
const excerptTypeSchema = new schema.Entity('excerptTypes');
const excerptSchema = new schema.Entity(
  'excerpts',
);
const documentTypeSchema = new schema.Entity('documentTypes');
const documentSchema = new schema.Entity(
  'documents',
  { pages: [pageSchema], excerpts: [excerptSchema] },
);
// Cases
const projectCaseSchema = new schema.Entity(
  'projectCases',
  { project: projectSchema },
);
const caseSchema = new schema.Entity(
  'cases',
  {
    project_cases: [projectCaseSchema],
    documents: [documentSchema],
    document_types: [documentTypeSchema],
    excerpt_types: [excerptTypeSchema]
  },
);

// Matches
const matchSchema = new schema.Entity('matches', {
  project: projectSchema,
  page: pageSchema,
  keyword: keywordSchema,
});

// References
const choiceSchema = new schema.Entity('choices');
const choiceListSchema = new schema.Entity('choiceLists', {
  choices: [choiceSchema]
});
const questionSchema = new schema.Entity('questions', {
  choice_list: choiceListSchema,
});
const referenceTypeSchema = new schema.Entity('referenceTypes', {
  project: projectSchema,
  questions: [questionSchema]
});
const answerSchema = new schema.Entity('answers', {
  question: questionSchema
});
const referenceSchema = new schema.Entity('references', {
  pages: [pageSchema],
  answers: [answerSchema],
  match: matchSchema,
  excerpt: excerptSchema,
});

export default createStore({
  state: () => ({
    user: null,
    cases: {},
    projectCases: {},
    projects: {},
    keywords: {},
    documents: {},
    pages: {},
    documentTypes: {},
    excerptTypes: {},
    excerpts: {},
    choiceLists: {},
    choices: {},
    questions: {},
    referenceTypes: {},
    matches: {},
    references: {},
    answers: {},
    autoNavigate: false,
    reset: false
  }),
  getters: {
    getUser: (state) => () => state.user, 
    getCase: (state) => (id) => state.cases[id], 
    getProjectCase: (state) => (id) => state.projectCases[id],
    getProjectCases: (state) => () => state.projectCases,
    getProject: (state) => (id) => state.projects[id],
    getKeyword: (state) => (id) => state.keywords[id],
    getDocument: (state) => (id) => state.documents[id],
    getDocuments: (state) => () => state.documents,
    getPage: (state) => (id) => state.pages[id],
    getDocumentType: (state) => (id) => state.documentTypes[id],
    documentTypes: (state) => state.documentTypes,
    getExcerpt: (state) => (id) => state.excerpts[id],
    getExcerptType: (state) => (id) => state.excerptTypes[id],
    excerptTypes: (state) => state.excerptTypes,
    getChoiceList: (state) => (id) => state.choiceLists[id],
    getChoice: (state) => (id) => state.choices[id],
    getQuestion: (state) => (id) => state.questions[id],
    getReferenceType: (state) => (id) => state.referenceTypes[id],
    getMatch: (state) => (id) => state.matches[id],
    getReference: (state) => (id) => state.references[id],
    getReferences: (state) => () => state.references,
    getAnswer: (state) => (id) => state.answers[id],
    getAutoNavigate: (state) => () => state.autoNavigate,
    getReset: (state) => () => state.reset,

    getReferenceTypesForProject: (state) => (id) => {
      return Object.keys(state.referenceTypes)
        .reduce((result, key) => {
          if (state.referenceTypes[key].project == id) {
            result.push(state.referenceTypes[key]);
          }
          return result;
        }, []);
    },
    getDocumentPages: (state, getters) => (documentId) => {
      let doc = getters.getDocument(documentId);
      if (!doc) {
        return null;
      }
      return getters.getDocument(documentId).pages
       .map((pageId) => getters.getPage(pageId))
        .sort((a, b) => a.pagenum - b.pagenum)
    },
    getPageIdsForDocument: (state, getters) => (documentId) => {
      return getters.getDocumentPages(documentId)
        .map((page) => page.id);
    },
    getDocumentExcerpts: (state, getters) => (documentId) => {
      return getters.getDocument(documentId).excerpts
       .map((eId) => getters.getExcerpt(eId))
    },
    getDocumentProjectMatches: (state, getters) => ({ documentId, projectId }) => {
      if (!documentId || !projectId) return [];
      let pageIds = getters.getPageIdsForDocument(documentId);
      return Object.keys(state.matches)
        .reduce((result, key) => {
          let match = state.matches[key];
          let keyword = state.keywords[match.keyword];
          if (keyword.project === projectId && pageIds.includes(match.page)) {
            result.push(match)
          }
          return result;
        }, []);
    },
    getProjectDocumentReferences: (state, getters) => ({ projectId, documentId }) => {
      if (!(projectId && documentId)) {
        return [];
      }
      let pageIds = getters.getPageIdsForDocument(documentId);
      let referenceTypes = getters.getReferenceTypesForProject(projectId);
      let referenceTypeIds = referenceTypes.map((rt) => rt.id);
      return Object.keys(state.references).reduce((result, key) => {
        let reference = getters.getReference(key);
        if (
          pageIds.includes(reference.page)
          && referenceTypeIds.includes(reference.reference_type)
        ) {
            result.push(reference);
          }
          return result;
        }, []);
    },
  },
  mutations: {
    reset(state) {
      state.cases = {};
      state.projectCases = {};
      state.projects = {};
      state.keywords = {};
      state.documents = {};
      state.pages = {};
      state.documentTypes = {};
      state.excerptTypes = {};
      state.excerpts = {};
      state.choiceLists = {};
      state.choices = {};
      state.questions = {};
      state.referenceTypes = {};
      state.matches = {};
      state.references = {};
      state.answers = {};
    },
    resetReferenceAnswers(state) {
      state.references = {}
      state.answers = {}
    },
    setUser(state, payload) {
      state.user = payload
    },
    addCases(state, cases) {
      state.cases = { ...state.cases, ...cases };
    },
    addProjectCases(state, projectCases) {
      state.projectCases = { ...state.projectCases, ...projectCases };
    },
    addProjects(state, projects) {
      state.projects = { ...state.projects, ...projects };
    },
    addKeywords(state, keywords) {
      state.keywords = { ...state.keywords, ...keywords};
    },
    addDocuments(state, documents) {
      state.documents = { ...state.documents, ...documents };
    },
    addPages(state, pages) {
      state.pages = { ...state.pages, ...pages };
    },
    addDocumentTypes(state, documentTypes) {
      state.documentTypes = { ...state.documentTypes, ...documentTypes };
    },
    addExcerptTypes(state, excerptTypes) {
      state.excerptTypes = { ...state.excerptTypes, ...excerptTypes };
    },
    addExcerpts(state, excerpts) {
      state.excerpts = { ...state.excerpts, ...excerpts };
    },
    addExcerptIdToDocument(state, { excerptId, documentId }) {
      state.documents[documentId].excerpts.push(excerptId);
    },
    deleteExcerpt(state, excerptId) {
      for (let document_ of Object.values(state.documents)) {
        let excerptIdx = document_.excerpts.indexOf(excerptId);
        if (excerptIdx > -1) {
          let clone = { ...document_ };
          clone.excerpts.splice(excerptIdx, 1);
          state.documents[document_.id] = clone;
        }
      }
      delete state.excerpts[excerptId];
    },
    addChoiceLists(state, choiceLists) {
      state.choiceLists = { ...state.choiceLists, ...choiceLists }
    },
    addChoices(state, choices) {
      state.choices = { ...state.choices, ...choices };
    },
    addQuestions(state, questions) {
      state.questions = { ...state.questions, ...questions };
    },
    addReferenceTypes(state, referenceTypes) {
      state.referenceTypes = { ...state.referenceTypes, ...referenceTypes };
    },
    addMatches(state, matches) {
      state.matches = { ...state.matches, ...matches };
    },
    addReferences(state, references) {
      state.references = { ...state.references, ...references };
    },
    addAnswers(state, answers) {
      state.answers = { ...state.answers, ...answers };
    },
    deleteReference(state, referenceId) {
      let reference = state.references[referenceId];
      for (let answer of reference.answers) {
        delete state.answers[answer];
      }
      delete state.references[referenceId];
    },
    deleteMatch(state, matchId) {
      state.matches[matchId].active = false;
    },
    setAutoNavigate(state, switchValue) {
      state.autoNavigate = switchValue
    },
    setReset(state, resetValue) {
      state.reset = resetValue
    }
  },
  actions: {
    logIn({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios.post('/login/', { username, password })
          .then((response) => {
            if(response.data['reset']){
              commit('setReset', true)
            }
            commit('setUser', response.data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
      });
    },
    logOut({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post('/logout/')
          .then(() => {
            commit('reset')
            commit('setUser', null)
            resolve();
          }).catch((error) => {
            reject(error);
          });
      });
    },
    loadUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/`)
          .then(response => {
            commit('setUser', response.data)
            resolve();
          }).catch((error) => {
            // TODO: Do something with the error
            console.log(error);
            reject(error);
          });
      });
    },
    loadCase({ commit }, caseId) {
      return new Promise((resolve, reject) => {
        commit('reset');
        axios.get(`/cases/${caseId}/`)
          .then(response => {
            let data = normalize(response.data, caseSchema);
            commit('addCases', data['entities']['cases']);
            commit('addProjectCases', data['entities']['projectCases']);
            commit('addProjects', data['entities']['projects']);
            commit('addKeywords', data['entities']['keywords']);
            commit('addDocuments', data['entities']['documents']);
            commit('addPages', data['entities']['pages']);
            commit('addDocumentTypes', data['entities']['documentTypes']);
            commit('addExcerptTypes', data['entities']['excerptTypes']);
            commit('addExcerpts', data['entities']['excerpts']);
            resolve();
          }).catch((error) => {
            // TODO: Do something with the error
            console.log(error);
            reject(error);
          });
      });
    },
    loadProjectReferenceTypes({ commit }, projectId ) {
      return new Promise((resolve, reject) => {
        let url = `/referencetypes/`;
        let params = { 'project_id': projectId };
        function handler(data) {
          let ndata = normalize(data, [referenceTypeSchema]);
          commit('addChoiceLists', ndata['entities']['choiceLists']);
          commit('addChoices', ndata['entities']['choices']);
          commit('addQuestions', ndata['entities']['questions']);
          commit('addReferenceTypes', ndata['entities']['referenceTypes']);
        }
        getPaginatedResults(url, params, handler)
          .then(() => {
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    loadProjectDocumentMatches({ commit }, {projectId, documentId }) {
      return new Promise((resolve, reject) => {
        let url = `/matches/`;
        let params = {
          'keyword__project_id': projectId,
          'page__document_id': documentId,
        };
        function handler(data) {
          let ndata = normalize(data, [matchSchema]);
          commit('addMatches', ndata['entities']['matches'])
        }
        getPaginatedResults(url, params, handler)
          .then(() => {
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error)
          });
      });
    },
    loadProjectDocumentReferences({ commit }, { projectId, documentId }) {
      return new Promise((resolve, reject) => {
        let url = `/references/`;
        let params = {
          'page__document_id': documentId,
          'reference_type__project_id': projectId,
        };
        function handler(data) {
          let ndata = normalize(data, [referenceSchema]);
          commit('addReferences', ndata['entities']['references']);
          commit('addAnswers', ndata['entities']['answers']);
        }
        return getPaginatedResults(url, params, handler)
          .then(() => {
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    loadCaseProjectReferences ({ commit }, { caseId, projectId }) {
      return new Promise((resolve, reject) => {
        let url = `/references/`;
        let params = {
          'page__document__case_id': caseId,
          'reference_type__project_id': projectId,
        };
        function handler(data) {
          let ndata = normalize(data, [referenceSchema]);
          commit('addReferences', ndata['entities']['references']);
          commit('addAnswers', ndata['entities']['answers']);
          commit('addExcerpts', ndata['entities']['excerpts']);
        }
        getPaginatedResults(url, params, handler)
          .then(() => {
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    createReference({ commit }, { referenceTypeId, matchId, excerptId, pageId, answers }) {
      console.log(excerptId);
      return new Promise((resolve, reject) => {
        axios.post('/references/', {
          reference_type: referenceTypeId,
          match: matchId,
          excerpt: excerptId,
          page: pageId,
          answers: answers,
        }).then((response) => {
          let data = normalize(response.data, referenceSchema)
          commit('addReferences', data['entities']['references']);
          commit('addAnswers', data['entities']['answers']);
          commit('addExcerpts', data['entities']['excerpts']);
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    updateReference({ commit }, { referenceId, referenceTypeId, matchId, excerptId, pageId, answers }) {
      return new Promise((resolve, reject) => {
        axios.put(`/references/${referenceId}/`, {
          reference_type: referenceTypeId,
          match: matchId,
          excerpt: excerptId,
          page: pageId,
          answers: answers,
        }).then((response) => {
          let data = normalize(response.data, referenceSchema)
          commit('addReferences', data['entities']['references']);
          commit('addAnswers', data['entities']['answers']);
          commit('addExcerpts', data['entities']['excerpts']);
          resolve()
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    updateDocument({ commit }, { documentId, form }) {
      return new Promise((resolve, reject) => {
        axios.post(`/documents/${documentId}/`, form)
          .then((response) => {
            let data = normalize(response.data, documentSchema);
            commit('addDocuments', data['entities']['documents'])
            resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    createExcerpt({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios.post(`/excerpts/`, {...form})
          .then((response) => {
            let data = normalize(response.data, excerptSchema);
            let excerptId = Object.keys(data['entities']['excerpts'])[0];
            commit('addExcerpts', data['entities']['excerpts']);
            commit('addExcerptIdToDocument', {
              documentId: form.document, excerptId: parseInt(excerptId)
            });
            resolve(excerptId);
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    editExcerpt({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios.patch(`/excerpts/${form.id}/`, {...form})
          .then((response) => {
            commit('deleteExcerpt', form.id);
            let data = normalize(response.data, excerptSchema);
            let excerptId = Object.keys(data['entities']['excerpts'])[0];
            commit('addExcerpts', data['entities']['excerpts']);
            commit('addExcerptIdToDocument', {
              documentId: form.document, excerptId: parseInt(excerptId)
            });
            resolve(excerptId);
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    deleteExcerpt({ commit }, excerptId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/excerpts/${excerptId}/`)
          .then(() => {
            commit('deleteExcerpt', excerptId);
            resolve();
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    deleteMatch({ commit }, matchId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/matches/${matchId}/`)
          .then(() => {
            commit('deleteMatch', matchId);
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    deleteReference({ commit }, referenceId) {
      return axios.delete(`/references/${referenceId}/`)
        .then(() => {
          commit('deleteReference', referenceId);
        }, (error) => {
          return Promise.reject(error);
        });
    },
    claimProjectCase({ commit, rootGetters }, projectCaseId) {
      return new Promise((resolve, reject) => {
        let projectCase = rootGetters.getProjectCase(projectCaseId);
        axios.post(`/cases/${projectCase.case.id}/claim/${projectCase.project}/`)
          .then((response) => {
            let data = normalize(response.data, caseSchema);
            commit('addCases', data['entities']['cases']);
            commit('addProjectCases', data['entities']['projectCases']);
            commit('addProjects', data['entities']['projects']);
            commit('addKeywords', data['entities']['keywords']);
            commit('addDocuments', data['entities']['documents']);
            commit('addPages', data['entities']['pages']);
            commit('addExcerpts', data['entities']['excerpts']);
            resolve();
          }, (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    submitProjectCase({ commit, rootGetters }, {projectCaseId, earlySubmissionReasonId}) {
      return new Promise((resolve, reject) => {
        let projectCase = rootGetters.getProjectCase(projectCaseId);
        let form = {};
        if (earlySubmissionReasonId) {
          form['early_submission_reason_id'] = earlySubmissionReasonId;
        }
        return axios.post(`/cases/${projectCase.case.id}/submit/${projectCase.project}/`, form)
          .then((response) => {
            let data = normalize(response.data, caseSchema);
            commit('addCases', data['entities']['cases']);
            commit('addProjectCases', data['entities']['projectCases']);
            commit('addProjects', data['entities']['projects']);
            commit('addKeywords', data['entities']['keywords']);
            commit('addDocuments', data['entities']['documents']);
            commit('addPages', data['entities']['pages']);
            commit('addExcerpts', data['entities']['excerpts']);
            commit('addExcerptTypes', data['entities']['excerptTypes']);
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    revertProjectCase({ commit, rootGetters }, { projectCaseId }) {
      return new Promise((resolve, reject) => {
        let projectCase = rootGetters.getProjectCase(projectCaseId);
        return axios.post(`/cases/${projectCase.case.id}/revert/${projectCase.project}/`)
          .then((response) => {
            let data = normalize(response.data, caseSchema);
            commit('addCases', data['entities']['cases']);
            commit('addProjectCases', data['entities']['projectCases']);
            commit('addProjects', data['entities']['projects']);
            commit('addKeywords', data['entities']['keywords']);
            commit('addDocuments', data['entities']['documents']);
            commit('addPages', data['entities']['pages']);
            commit('addExcerpts', data['entities']['excerpts']);
            commit('addExcerptTypes', data['entities']['excerptTypes']);
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    setProjectCaseReviewResult({ commit, rootGetters }, {projectCaseId, reviewResultId}) {
      return new Promise((resolve, reject) => {
        let projectCase = rootGetters.getProjectCase(projectCaseId);
        let form = {
          review_result_id: reviewResultId
        };
        return axios.post(`/cases/${projectCase.case.id}/result/${projectCase.project}/`, form)
          .then((response) => {
            let data = normalize(response.data, caseSchema);
            commit('addCases', data['entities']['cases']);
            commit('addProjectCases', data['entities']['projectCases']);
            commit('addProjects', data['entities']['projects']);
            commit('addKeywords', data['entities']['keywords']);
            commit('addDocuments', data['entities']['documents']);
            commit('addPages', data['entities']['pages']);
            commit('addExcerpts', data['entities']['excerpts']);
            commit('addExcerptTypes', data['entities']['excerptTypes']);
            resolve();
          }).catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
  },
  modules: {
    viewer,
  }
})
