const viewer = {
  namespaced: true,
  state: {
    caseId: null,
    documentId: null,
    projectCaseId: null,
    highlightPageIds: [],
  },
  mutations: {
    setCaseId(state, caseId) {
      state.caseId = caseId;
    },
    setProjectCaseId(state, projectCaseId) {
      state.projectCaseId = projectCaseId;
    },
    setDocumentId(state, documentId) {
      state.documentId = documentId;
    },
    setHighlightPageIds(state, pageIds) {
      state.highlightPageIds = pageIds;
    },
  },
  actions: {
    setCaseId({ commit, dispatch }, caseId) {
      return new Promise((resolve, reject) => {
        commit('setCaseId', caseId);
        dispatch('loadCase', caseId, { root: true })
          .then(resolve)
          .catch(reject);
      });
    },
    setProjectCaseId({ commit }, projectCaseId) {
      return new Promise((resolve) => {
        commit('setProjectCaseId', projectCaseId);
        resolve();
      });
    },
    setDocumentId({ commit }, documentId) {
      return new Promise((resolve) => {
        commit('setDocumentId', documentId);
        resolve();
      });
    },
    setHighlightPageIds({ commit }, pageIds) {
      return new Promise((resolve) => {
        commit('setHighlightPageIds', pageIds);
        resolve();
      });
    },
  },
  getters: {
    caseId: (state) => state.caseId,
    projectCaseId: (state) => state.projectCaseId,
    documentId: (state) => state.documentId,
    projectId: (state, getters) => {
      if (!getters.projectCase) {
        return null;
      }
      return getters.projectCase.project;
    },
    case_: (state, getters, rootState, rootGetters) => {
      return rootGetters.getCase(state.caseId);
    },
    document_: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocument(state.documentId);
    },
    documentPages: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocumentPages(state.documentId);
    },
    pageIds: (state, getters, rootState, rootGetters) => {
      if (!(getters.document_)) {
        return [];
      }
      return rootGetters.getPageIdsForDocument(getters.document_.id);
    },
    documentExcerpts: (state, getters, rootState, rootGetters) => {
      if (!getters.document_) {
        return [];
      }
      return getters.document_.excerpts
        .map((eId) => rootGetters.getExcerpt(eId));
    },
    projectCase: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProjectCase(state.projectCaseId);
    },
    project: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProject(getters.projectId);
    },
    referenceTypes: (state, getters, rootState, rootGetters) => {
      if (!getters.projectId) {
        return [];
      }
      return rootGetters.getReferenceTypesForProject(getters.projectId);
    },
    references: (state, getters, rootState, rootGetters) => {
      return rootGetters.getProjectDocumentReferences({
        projectId: getters.projectId,
        documentId: getters.documentId,
      });
    },
    matches: (state, getters, rootState, rootGetters) => {
      return rootGetters.getDocumentProjectMatches({
        documentId: getters.documentId,
        projectId: getters.projectId,
      });
    },
    highlightPageIds: (state) => state.highlightPageIds,
  },
};

export default viewer;
